import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getDailyAQI } from "../../api/getDailyAQI";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { GoogleMapBox } from "../../components/Map";
import { ParameterCard } from "../../components/paramaterCard";
import { populate } from "./DailyAQISlice";
import { FaBatteryFull, GiPlug, MdSignalCellular4Bar } from "react-icons/all";
import { info } from "../../components/rangeInfo/AQIDangerRanges";
import { MapMarker } from "../../components/Map/mapmarker";
import { Redirect } from "react-router";
import { changeDevice } from "../../app/InputSlice";
import { AQIBadge } from "../../components/rangeInfo/AQIBadge";
import { getTodaysData } from "../../api/getTodaysData";
import moment from "moment";
import { getTodaysData2 } from "../../api/getTodaysData2";

const DailyAQI: React.FC<{}> = () => {
  const devices = useSelector((state: RootState) => state.devices);
  const daily_data = useSelector((state: RootState) => state.daily);
  // const hourly_data = useAppSelector(s => s.hourly);
  // const firstRow = !!hourly_data && hourly_data.length > 0 ? hourly_data[0] : {int_humi_avg: "N/A",ext_temp_avg: "N/A"};
  const data = daily_data[0];
  const dispatch = useAppDispatch();

  const { device } = useSelector((root: RootState) => root.input);
  const [newData, setNewData] = useState();

  const selectedHelpText = useMemo(
    () =>
      info.HelpText.map((i) => ({
        AQI_Start: parseInt(i.AQI.split("-")[0]),
        AQI_End: parseInt(i.AQI.split("-")[1]),
        ...i,
      })),
    []
  );
  useEffect(() => {
    const callAPI = async () => {
      if (device === null) return;
      dispatch(populate([]));
      console.log("calling api");
      const data = await getTodaysData(device.dev_id, moment());
      const data2 = await getTodaysData2(device.dev_id);
      setNewData(data2.data.data);
      dispatch(populate(data.data.data));
      console.log("dispatching data");
    };
    callAPI();
  }, [device]);
  const { state } = useSelector((state: RootState) => state.user);
  if (state !== "loggedIn") return <Redirect to="/signin" />;
  return (
    <>
      <div className="flex flex-row flex-1 justify-start items-stretch p-4 gap-4">
        <GoogleMapBox
          options={
            device !== null
              ? {
                  center: {
                    lat: parseFloat(device.lat),
                    lng: parseFloat(device.long),
                  },
                }
              : {}
          }
        >
          {!!devices && devices.length > 0
            ? devices
                .filter((d) => d.lat !== null && d.long !== null)
                .map((d) =>
                  device?.dev_id === d.dev_id ? (
                    <MapMarker
                      lat={parseFloat(d.lat)}
                      lng={parseFloat(d.long)}
                      text={d.location}
                      aqi={parseInt(d.aqi)}
                      dev_id={d.dev_id}
                      district={d.district}
                      selected
                    />
                  ) : (
                    <MapMarker
                      lat={parseFloat(d.lat)}
                      lng={parseFloat(d.long)}
                      text={d.location}
                      aqi={parseInt(d.aqi)}
                      dev_id={d.dev_id}
                      district={d.district}
                    />
                  )
                )
            : null}
        </GoogleMapBox>
        {!!device ? (
          <>
            <div className="flex flex-1 flex-col justify-start items-stretch pb-4 overflow-hidden rounded-md bg-blend-overlay bg-white bg-opacity-25">
              <div className="flex flex-row justify-start items-stretch w-full bg-blue-400 rounded-md p-4 text-gray-100 font-semibold font-sans text-xl shadow-lg">
                {/* <AQIBadge aqi={dailyaqi[0]?.AQI} /> */}
                {/* {selectedDevice?.location} */}
                <span className="flex flex-row justify-start items-stretch text-left gap-4 shadow-lg rounded-md bg-transparent m-0 p-0">
                  <div className="m-auto pl-2 text-center">Device</div>
                  <select
                    name="devices"
                    id="devices"
                    className="p-2 outline-none bg-transparent rounded-md text-gray-50 border-0 rounded-l-none placeholder-gray-800 border-l-2 border-gray-300"
                    defaultValue="null"
                    onChange={(e) => {
                      const newDevice = devices.find(
                        (d) => d.dev_id === e.currentTarget.value
                      );
                      if (!!newDevice) dispatch(changeDevice(newDevice));
                    }}
                    value={device?.dev_id}
                  >
                    <option
                      value="null"
                      className="text-gray-100 bg-blue-400"
                      disabled
                    >
                      Select Any
                    </option>
                    {devices.map((d) => (
                      <option
                        value={d.dev_id}
                        className="text-gray-100 bg-blue-400"
                      >
                        {d.location}
                      </option>
                    ))}
                  </select>
                </span>
              </div>
              <div className="bg-gray-800 p-2 m-2 rounded-md">
                <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-xl">
                  <div>Device Location: </div>
                  <div className="text-gray-50 text-xl font-bold px-4">
                    {device.location}
                  </div>
                </div>
                <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-xl">
                  <div>Date and Hour: </div>
                  <div className="text-gray-50 text-xl font-bold px-4">
                    {!!data?.date && typeof data?.hour !== "undefined"
                      ? data.date + ", " + data.hour
                      : "N/A"}{" "}
                    hours
                  </div>
                </div>

                {typeof data?.hour !== "undefined" &&
                data.hour !== new Date().getHours() ? (
                  <div className="flex flex-row justify-start items-center p-4 py-1 -mt-2">
                    <div className="text-red-300 text-md">
                      Latest Data unavailable.
                    </div>
                  </div>
                ) : null}
                <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-xl">
                  <div>Latitude: </div>
                  <div className="text-gray-50 text-xl font-bold px-4">
                    {device.lat}
                  </div>
                </div>
                <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-xl">
                  <div>Longitude: </div>
                  <div className="text-gray-50 text-xl font-bold px-4">
                    {device.long}
                  </div>
                </div>
                <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-xl">
                  <div>Relative Humidity: </div>
                  <div className="text-gray-50 text-xl font-bold px-4">
                    {
                      //@ts-ignore
                      !!newData?.ext_humi ? newData?.ext_humi : "N/A"
                    }{" "}
                    %
                  </div>
                </div>
                <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-xl">
                  <div>Temperature: </div>
                  <div className="text-gray-50 text-xl font-bold px-4">
                    {
                      //@ts-ignore
                      !!newData?.ext_temp ? newData?.ext_temp : "N/A"
                    }{" "}
                    °C
                  </div>
                </div>
                <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-xl">
                  <div>AQI: </div>
                  <div className="text-gray-50 text-xl font-bold px-4">
                    {
                      //@ts-ignore
                      !!data?.AQI ? data?.AQI : "N/A"
                    }{" "}
                  </div>
                </div>
                <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-xl">
                  <div>Prominent Pollutant: </div>
                  <div className="text-gray-50 text-xl font-bold px-4">
                    {
                      //@ts-ignore
                      !!data?.Prompt_Pol ? data?.Prompt_Pol : "N/A"
                    }{" "}
                  </div>
                </div>
              </div>
              {typeof data !== "undefined" ? (
                <div className="flex flex-row justify-center items-center m-2">
                  <table className="bg-gray-700 w-full table">
                    <thead className="table-header-group">
                      <tr className="table-row">
                        <th className="p-2 py-4 text-white bg-gray-700 mx-1 text-left">
                          Pollutant
                        </th>
                        <th className="p-2 py-4 text-white bg-gray-700 mx-1 text-left">
                          Average
                        </th>
                        <th className="p-2 py-4 text-white bg-gray-700 mx-1 text-left">
                          Minimum
                        </th>
                        <th className="p-2 py-4 text-white bg-gray-700 mx-1 text-left">
                          Maximum
                        </th>
                      </tr>
                    </thead>
                    <tbody className="table-row-group p-2 text-gray-200">
                      <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                        <td className="p-2 py-4">
                          SO<sub>2</sub>
                        </td>
                        <td className="p-2 py-4">
                          {data.so2_avg} &#181;g/m<sup>3</sup>
                        </td>
                        <td className="p-2 py-4">
                          {data.so2_min} &#181;g/m<sup>3</sup>
                        </td>
                        <td className="p-2 py-4">
                          {data.so2_max} &#181;g/m<sup>3</sup>
                        </td>
                      </tr>
                      <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                        <td className="p-2 py-4">
                          NO<sub>2</sub>
                        </td>
                        <td className="p-2 py-4">
                          {data.no2_avg} &#181;g/m<sup>3</sup>
                        </td>
                        <td className="p-2 py-4">
                          {data.no2_min} &#181;g/m<sup>3</sup>
                        </td>
                        <td className="p-2 py-4">
                          {data.no2_max} &#181;g/m<sup>3</sup>
                        </td>
                      </tr>
                      <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                        <td className="p-2 py-4">PM 2.5</td>
                        <td className="p-2 py-4">
                          {data.pm25_avg} &#181;g/m<sup>3</sup>
                        </td>
                        <td className="p-2 py-4">
                          {data.pm25_min} &#181;g/m<sup>3</sup>
                        </td>
                        <td className="p-2 py-4">
                          {data.pm25_max} &#181;g/m<sup>3</sup>
                        </td>
                      </tr>
                      <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                        <td className="p-2 py-4">PM 10</td>
                        <td className="p-2 py-4">
                          {data.pm10_avg} &#181;g/m<sup>3</sup>
                        </td>
                        <td className="p-2 py-4">
                          {data.pm10_min} &#181;g/m<sup>3</sup>
                        </td>
                        <td className="p-2 py-4">
                          {data.pm10_max} &#181;g/m<sup>3</sup>
                        </td>
                      </tr>
                      <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                        <td className="p-2 py-4">PM 1</td>
                        <td className="p-2 py-4">
                          {data.pm1_avg} &#181;g/m<sup>3</sup>
                        </td>
                        <td className="p-2 py-4">
                          {data.pm1_min} &#181;g/m<sup>3</sup>
                        </td>
                        <td className="p-2 py-4">
                          {data.pm1_max} &#181;g/m<sup>3</sup>
                        </td>
                      </tr>
                      <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                        <td className="p-2 py-4">Temperature</td>
                        <td className="p-2 py-4">
                          {data.ext_temp_avg} &#176;C
                        </td>
                        <td className="p-2 py-4">
                          {data.ext_temp_min} &#176;C
                        </td>
                        <td className="p-2 py-4">
                          {data.ext_temp_max} &#176;C
                        </td>
                      </tr>
                      <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                        <td className="p-2 py-4">Humidity</td>
                        <td className="p-2 py-4">{data.ext_humi_avg} %</td>
                        <td className="p-2 py-4">{data.ext_humi_min} %</td>
                        <td className="p-2 py-4">{data.ext_humi_max} %</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="p-4 font-bold font-sans text-gray-800 text-xl">
                  No Data Available
                </div>
              )}
            </div>
          </>
        ) : null}
        {/* <div className="flex flex-1 flex-row justify-start items-end text-lg font-sans font-semibold p-4 w-full gap-4">
            <div className="flex flex-col justify-end items-center gap-2">
              <span
                className="rounded-full p-8 shadow-lg bg-gray-100 text-gray-600 font-sans font-bold text-center flex flex-col justify-center items-center text-xl hover:bg-gray-600 hover:text-gray-100 transition-colors duration-200"
                style={{ width: 100, height: 100 }}
              >
                <FaBatteryFull size="60px" />
              </span>
              Battery
            </div>
            <div className="flex flex-col justify-end items-center gap-2">
              <span
                className="rounded-full p-8 shadow-lg bg-gray-100 text-gray-600 font-sans font-bold text-center flex flex-col justify-center items-center text-xl hover:bg-gray-600 hover:text-gray-100 transition-colors duration-200"
                style={{ width: 100, height: 100 }}
              >
                <GiPlug size="60px" />
              </span>
              Power
            </div>
            <div className="flex flex-col justify-end items-center gap-2">
              <span
                className="rounded-full p-8 shadow-lg bg-gray-100 text-gray-600 font-sans font-bold text-center flex flex-col justify-center items-center text-xl hover:bg-gray-600 hover:text-gray-100 transition-colors duration-200"
                style={{ width: 100, height: 100 }}
              >
                <MdSignalCellular4Bar size="60px" />
              </span>
              Network
            </div>
            <div className="flex flex-row justify-end items-order flex-1">
              <button className="p-2 rounded-md shadow-md bg-blue-400 text-gray-100">
                Get More Diagnostic Data
              </button>
            </div>
          </div> */}
      </div>
    </>
  );
};

export default DailyAQI;
