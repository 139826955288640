import React from "react";
import ReactTooltip from "react-tooltip";
import { FaMapMarkerAlt } from "react-icons/all";
import { changeDevice } from "../../app/InputSlice";
import { useAppDispatch } from "../../app/hooks";
import {
  getDeviceList_returns_data_item as Device,
  getDeviceList_returns_data_item,
} from "../../api/getDeviceList";

export const getColorFromAQIValue = (aqi: number | undefined) => {
  if (aqi !== undefined && aqi >= 0) {
    switch (true) {
      case aqi === 0:
        return "#efefef";
      case aqi < 50:
        return "#00B050";
      case aqi < 100:
        return "#92D050";
      case aqi < 200:
        return "#FFFF00";
      case aqi < 300:
        return "#FFC000";
      case aqi <= 400:
        return "#FF0000";
      case aqi > 400:
        return "#C00000";
    }
  }
};

export const MapMarker: React.FC<{
  lat: number;
  lng: number;
  text: string;
  aqi: number;
  selected?: boolean;
  dev_id: string;
  district?: string | undefined | null;
}> = ({ text, selected, aqi, dev_id, lat, lng, district }) => {
  const dispatch = useAppDispatch();
  const handleClick = () => {
    const item: getDeviceList_returns_data_item = {
      dev_id: dev_id,
      location: text,
      lat: String(lat),
      long: String(lng),
      aqi: String(aqi),
      district: district,
    };
    if (!!item) {
      dispatch(changeDevice(item as Device));
    }
  };
  let color = getColorFromAQIValue(aqi);
  return (
    <>
      <div
        className="hover:text-gray-800 text-transparent font-bold hover:shadow-lg text-center cursor-pointer"
        data-tip={text}
        onClick={handleClick}
      >
        <span
          className={`absolute inline-flex h-10 w-10 rounded-full opacity-30 border-2 border-white ${!!selected ? "animate-ping" : ""}`}
          style={{ backgroundColor: color }}
        ></span>
        <div
          className={`flex flex-row justify-center items-center z-10 rounded-full w-10 h-10 text-gray-200 border-2 border-white ${
            !!selected ? "" : ""
          }`}
          style={{ background: color }}
        >
          <p className="-pt-1 text-gray-900 font-bold text-base">{aqi}</p>
        </div>
      </div>
      <ReactTooltip />
    </>
  );
};

export const MapMarkerMemoized = React.memo(MapMarker);

// export const MapMarker: React.FC<{
//   onClick: () => void;
//   lat: number;
//   lng: number;
//   text: string;
//   aqi: number;
//   selected?: boolean;
// }> = ({ text, selected, aqi, onClick }) => {
//   return (
//     <>
//       <div
//         className="hover:text-gray-800 text-transparent font-bold hover:shadow-lg text-center cursor-pointer"
//         data-tip={text}
//         onClick={onClick}
//       >
//         <span
//           className="animate-ping absolute inline-flex h-7 w-7 rounded-full opacity-40"
//           style={{ backgroundColor: color }}
//         ></span>
//         <div
//           className={`flex flex-row justify-center items-center z-10 rounded-full w-8 h-8 text-gray-200 border-2 border-white ${
//             !!selected ? "" : ""
//           }`}
//           style={{ background: color }}
//         >
//           <p className="-pt-1 text-gray-900">{aqi}</p>
//         </div>
//         <ReactTooltip />
//       </div>
//     </>
//   );
// };

// export const MapMarkerMemoized = React.memo(MapMarker);
